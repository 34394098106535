.formWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error {
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: red;
  padding-top: 10px;
}
.login {
  width: 400px;
  height: 330px;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.52);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.6);
}

.lContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
}

.lInput {
  height: 30px;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 1px solid rgba(255, 255, 255, 0.5);
}

.lButton {
  border: none;
  padding: 10px 20px;
  background-color: #0071c2;
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.lButton:disabled {
  background-color: #0071c28c;
  cursor: not-allowed;
}
